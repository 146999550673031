import { Translations } from "../translations"
import { isValidEmail } from "../validation"
import { isBoolean } from "../../utils/global"
export default function validate(values) {
  let errors = {}
  if (!values.email) {
    errors.email = Translations.ERROR.EMAIL_REQUIRED
  } else if (!isValidEmail(values.email)) {
    errors.email = Translations.ERROR.INVALID_EMAIL
  }

  if (!values.password && !isBoolean(process.env.LOGIN_WITHOUT_PASSWORD)) {
    errors.password = Translations.ERROR.PASSWORD_REQUIRED
  }

  if (document.querySelector("#recaptcha-container") && !values.gReCaptcha) {
    errors.gReCaptcha = Translations.ERROR.CAPTCHA_REQUIRED;
  }
    
  return errors
}
